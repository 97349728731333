import React from 'react';
import { Route } from 'react-router-dom';

// import Applicants from '../../../Applicants/Applicant';
// import ApplicantDetails from '../../../Applicants/ApplicantDetails';
// import ApplicantPay from '../../../Applicants/ApplicantPay';
// import ApplicantSwitch from '../../../Applicants/ApplicantSwitch';
import Layout from '../../../Layout/index';
import { UserSearch } from '../../../Users/usersearch';
import { UserDetails } from '../../../Users/userdetails';
import { Games } from '../../../Users/games';
import { Statement } from '../../../Users/statement';
import { DailyDeposit } from '../../../Transactions/dailydeposits';

import DepositPerHour from '../../../Transactions/depositPerHour';




import { DepositRequests } from '../../../Transactions/depositrequests';
import { WithdrawRequest } from '../../../Transactions/withdrawalrequests';
import { DailyWithdrawal } from '../../../Transactions/dailywithdraw';
import { DepositRequestOne } from '../../../Transactions/depositrequestOne';
import { WithdrawRequestOne } from '../../../Transactions/withdrawalrequestOne';
import { BlackList } from '../../../blackList/blacklist';
import { Ledger } from '../../../Ledger/Legder';
import { EWallet } from '../../../Ewaller/ewallet';
import { GamingIncome } from '../../../Ewaller/gameincome';
import { Winners } from '../../../Users/Winners';
import { NetCashFlow } from '../../../NetCashFlow/netcashflow';
import { BetPerHour } from '../../../Bets/bets';
import { BetsPerDay } from '../../../Bets/betsperday';
import { AverageBet } from '../../../Bets/AvarageBet';
import { TimePlayed } from '../../../Bets/timeplayed';
import { createBlackList } from '../../../blackList/createBlackList';
import LoginAudit from '../../../Users/LoginAudit';
import { USSDLogs } from '../../../Users/uusdlogs';

import { SendBlast } from '../../../Blast/sendBlast';
import { SendBlastIanctive } from '../../../Blast/sendBlastInactive.jsx';


import FileUploadDefault from '../../../CSV/components/FileUploadDefault';
import { SendBlastlUCKY4 } from '../../../Blast/sendBlastLucky4';
import { Customers } from '../../../Users/Customers';

import { Players } from '../../../Users/Players';

import { BoundMessages } from '../../../Users/bounds';
import { CustomersPresenter } from '../../../Users/CustomersPresenters';
import { NewlyCustomersCount } from '../../../Users/perday';
import UsersStaffs from '../../../Users/UsersStaffs';
import { PlayersHour } from '../../../Users/PlayersP';
import { CustomerHour } from "../../../Users/CustomersPerHour.jsx";


export default () => (
  <div>
    <Layout />
    <div className="container__wrap">


      <Route path="/find_user" component={UserSearch} />
      <Route path="/userdetail" component={UserDetails} />
      <Route path="/game" component={Games} />

      <Route path="/bound" component={BoundMessages} />

      <Route path="/customer_presenter" component={CustomersPresenter} />

      <Route path="/per_day_customers" component={NewlyCustomersCount} />

      <Route path="/users" component={UsersStaffs} />

      <Route path="/stats" component={CustomerHour} />


      <Route path="/statement" component={Statement} />

      <Route path="/statistics" component={Customers} />

      <Route path="/players" component={Players} />
      <Route path="/player_hours" component={PlayersHour} />


      <Route path="/sendblast" component={SendBlast} />
      <Route path="/sendInactiveBlast" component={SendBlastIanctive} />

      <Route path="/daily_deposits" component={DailyDeposit} />

      <Route path="/deposit_per_hour" component={DepositPerHour} />

      <Route path="/deposit_requests" component={DepositRequests} />

      <Route path="/withdrawal_request" component={WithdrawRequest} />
      <Route path="/daily_withdrawal" component={DailyWithdrawal} />
      <Route path="/deposit_request_one" component={DepositRequestOne} />

      <Route path="/withdraw_request_one" component={WithdrawRequestOne} />

      <Route path="/reconciliation" component={FileUploadDefault} />

      <Route path="/lucky4" component={SendBlastlUCKY4} />



      <Route path="/blacklist" component={BlackList} />

      <Route path="/ledger" component={Ledger} />

      <Route path="/ewallet" component={EWallet} />

      <Route path="/game_income" component={GamingIncome} />

      <Route path="/winners" component={Winners} />




      <Route path="/netcashflow" component={NetCashFlow} />

      <Route path="/bet_per_hour" component={BetPerHour} />

      <Route path="/bet_per_day" component={BetsPerDay} />

      <Route path="/avarage_bet" component={AverageBet} />

      <Route path="/time_played" component={TimePlayed} />

      <Route path="/addblacklist" component={createBlackList} />

      <Route path="/login_audit" component={LoginAudit} />
      <Route path="/ussdlogs" component={USSDLogs} />









      {/* <Route path="/applicant_details" component={ApplicantDetails} />

     
      <Route path="/applicant" component={Applicants} />

      
      <Route path="/applicant_records" component={ApplicantPay} />

      <Route path="/applicant_switch" component={ApplicantSwitch} />

      <Route path="/add_applicant" component={AddApplicant} />

     
      <Route path="/edit_profile" component={EditApplicant} />

      
 */}


    </div>
  </div>
);
