import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { ROLE } from '../../../configs/exports';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => {
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      {/* {ROLE === '1' ?
        <>
          <SidebarLink
            title="Statistics"
            icon="briefcase"
            route="/statistics"
            onClick={hideSidebar}
          />
          <SidebarLink
            title="Players"
            icon="users"
            route="/players"
            onClick={hideSidebar}
          />

          {/* <SidebarLink
            title="Presenters"
            icon="users"
            route="/users"
            onClick={hideSidebar}
          /> 
          
          */}
        {/* </> */}
        {/* // : */} 

        <>
          <SidebarLink
            title="Statistics Daily"
            icon="users"
            route="/players"
            onClick={hideSidebar}
          />
          <SidebarLink
            title="Statistics Hourly"
            icon="briefcase"
            route="/stats"
            onClick={hideSidebar}
          />
           <SidebarLink
            title="Presenters"
            icon="users"
            route="/users"
            onClick={hideSidebar}
          /> 
</>
        {/* </>

      } */}

{/* 
      <ul className="sidebar__block">
        <SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>
          <button className="sidebar__link" type="button" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button className="sidebar__link" type="button" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory>

      </ul> */}
      <ul className="sidebar__block">
        {/* <SidebarLink title="Log Out" icon="exit" route="/" /> */}
      </ul>
    </div >
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
